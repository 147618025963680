import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withRouter } from 'react-router-dom';

const Billing = (props) => {
    const {
        buttonLabel,
        className
    } = props;
    const [total, setTotal] = useState(0)
    let tax = 2
    const toggle = () => props.setBillingModal(!props.billingModal);
    const getBillSplitted = () => {
        if (!props.totalBlocks) {
            return <></>
        } else {
            let thousand = 0, rest = 0
            if (props.totalBlocks < 1000) {
                thousand = 1000
                rest = 0
            } else {
                thousand = 1000
                rest = props.totalBlocks - 1000
            }
            let array = [{ features: thousand, rate: 0.03, amount: 0.03 * thousand }]
            if (rest) {
                array.push({ features: rest, rate: 0.01, amount: 0.01 * rest })
            }
            return array.map((a, i) => {
                return (
                    <>
                        <li className="p1">
                            <div>{i * 1000 + 1}-{a.features}</div>
                            <div>{a.rate} / feature</div>
                        </li>
                        <li>
                            <div>{a.features}</div>
                            <div>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(a.amount)}</div>
                        </li>
                        <div className="line mt-3" />
                    </>
                )
            })
        }
    }
    useEffect(() => {
        if (props.totalBlocks) {
            let thousand = 0, rest = 0
            if (props.totalBlocks < 1000) {
                thousand = props.totalBlocks
                rest = 0
            } else {
                thousand = 1000
                rest = props.totalBlocks - 1000
            }
            let array = [0.03 * thousand]
            if (rest) {
                array.push(0.01 * rest)
            }
            setTotal(array.reduce((a, c) => a + c, 0))
        }
    }, [props.totalBlocks])
    return (
        <Modal isOpen={props.billingModal} toggle={toggle} className="billing-container">
            <ModalBody >
                <h3>Billing Summary</h3>
                <p className="mb-1">Your Data contains <span className="highlight">{props.totalBlocks} features</span></p>
                <ul className="mb-4">
                    {
                        getBillSplitted()
                    }
                    <li className="p1 mt-3">
                        <div>Flat Rate</div>
                        <div>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(300)}</div>
                    </li>
                    <li className="p1 mt-3">
                        <div>Subtotal</div>
                        <div>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total + 300)}</div>
                    </li>
                    <li className="p1">
                        <div>Taxes</div>
                        <div>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(tax)}</div>
                    </li>
                    <li className="total mt-2">
                        <div>Total</div>
                        <div>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total + 300 + tax)}</div>
                    </li>
                </ul>

                <div className="button-container">
                    <button onClick={() => props.onBuy(total + 300 + tax)}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total + 300 + tax)}- Buy Now</button>
                </div>
            </ModalBody>

        </Modal >
    );
}

export default withRouter(Billing);