import React, { useState } from 'react';
import './loading.scss';
import { Progress } from 'reactstrap';

function Loading(props) {
  return (
    <div className="cover">
      {!props.isGenerating ?
        <div className="sk-chase">
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
        </div>
        :
        (
          <div className="w-75">
            <Progress color="primary" value={`${props.progress}`} />
          </div>

        )
      }
      <h4>{props.message}</h4>
    </div >
  );
}

export default Loading;
