import React, { useEffect } from 'react'
import { StripeProvider, Elements } from 'react-stripe-elements'
import CheckoutForm from './CheckoutForm'
import { Modal, ModalBody } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

const Checkout = ({ selectedProduct, history, toggle, paymentModal, onPayment, totalAmount, onApplyCoupon, couponApplied, setCouponApplied }) => {
  return (
    <Modal isOpen={paymentModal} toggle={toggle} className="billing-container">
      <ModalBody >
        <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PK}>
          <Elements>
            <CheckoutForm selectedProduct={selectedProduct} history={history} onPayment={onPayment} totalAmount={totalAmount} onApplyCoupon={onApplyCoupon} couponApplied={couponApplied} setCouponApplied={setCouponApplied} />
          </Elements>
        </StripeProvider>
      </ModalBody>
    </Modal>
  )
}

export default withRouter(Checkout)
