import React, { useState } from 'react'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe
} from 'react-stripe-elements'
import './CheckoutForm.scss';
import { ToastContainer } from 'react-toastify';
import { withRouter } from 'react-router-dom'

const CheckoutForm = ({ selectedProduct, stripe, history, onPayment, totalAmount, onApplyCoupon, couponApplied, setCouponApplied }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [address, setAddress] = useState('')
  const [code, setCode] = useState('')

  if (selectedProduct === null) {
    history.push('/')
    return null
  }


  const handleSubmit = async event => {
    console.log(event.target.forms)
    var formData = new FormData(event.target);
    console.log(formData, formData.get('name'))
    event.preventDefault()
    const { token } = await stripe.createToken({

    })
    onPayment({
      token,
      name,
      email,
      address,
      code: code || undefined,
    })
  }
  const onCouponChange = (value) => {
    setCode(value)
    if (couponApplied) {
      setCouponApplied(false)
    }
  }
  return (
    <div className="checkout-form">
      <p className="mb-0">Amount: <span className={couponApplied ? 'striked font-weight-bold' : 'font-weight-bold'}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalAmount + 300)}</span> {couponApplied && <span className="font-weight-bold">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format((totalAmount + 300) * (100 - couponApplied.percentage) * 0.01)}</span>}</p>
      <p className="mt-0">A flat fee of <span className="font-weight-bold">$300.00</span> has been added to the tiered pricing.</p>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            <div class="form-group">
              <label for="exampleInputEmail1">Name</label>
              <input type="name" class="form-control" onChange={e => setName(e.target.value)} required />
            </div>
          </div>
          <div className="col-md-6">
            <div class="form-group">
              <label for="exampleInputEmail1">Email</label>
              <input type="email" class="form-control" onChange={e => setEmail(e.target.value)} required />
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="exampleInputEmail1">Address</label>
          <input type="address" class="form-control" onChange={e => setAddress(e.target.value)} />
        </div>

        <div className="card-info">
          <div class="form-group cardnum">
            <label>Card details</label>
            <CardNumberElement />
          </div>

          <div class="form-group exp">
            <label>Expiration</label>
            <CardExpiryElement />
          </div>

          <div class="form-group cvc">
            <label>CVC</label>
            <CardCVCElement />
          </div>
        </div>
        <div className="row coupon">
          <div className="col-md-8">
            <div class="form-group">
              <label for="coupon">Coupon Code</label>
              <input type="text" class="form-control" onChange={e => onCouponChange(e.target.value)} name="coupon" />
            </div>
          </div>
          <div className="col-md-4">
            <button type="button" onClick={() => onApplyCoupon(code)}>Apply Coupon</button>
          </div>
        </div>
        <p>After payment you'll be emailed the results of your query</p>
        <button type="submit" className="order-button">Pay</button>
      </form>
      <ToastContainer />
    </div >
  )
}

export default injectStripe(withRouter(CheckoutForm))
