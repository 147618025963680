import React, { useState, useRef } from 'react';
import YouTube from 'react-youtube';

const Upload = ({ onUploadCSV }) => {
  const inputEl = useRef(null);
  const onClick = (e) => {
    inputEl.current.click();
  }
  return (
    <>
      <form className="upload-form">
        <div className="uploader" onClick={onClick}>
          <span className="title">Click here to upload your 2010 Block IDs</span>
          <input type="file" hidden ref={inputEl} onChange={onUploadCSV} accept=".csv" />
        </div>

      </form>
      <div className="instructions">
        <h6>Instructions:</h6>
        <ul>
          <li>Download the csv template <a href="https://geojson-assets.s3.us-east-2.amazonaws.com/477_Converter_Template.csv" className="upload-format-link">here</a></li>
          <li> Be sure BlockCode and TechCode columns are populated</li>
          <li> BlockCode should be formatted as a number (no scientific notation)</li>
          <li>Save as CSV</li>
          <li>Upload your list</li>
          <li>Complete payment</li>
          <li> You’ll receive an email with 2 KMZ files for each tech code:</li>
          <ul className="dot">
            <li> 2020 blocks that intersect with your 2010 blocks</li>
            <li> Adjacent blocks so you can identify additional blocks you may need to report</li>
          </ul>
          <li> You will need to manually verify all perimeter blocks to ensure the new block list does not over state or understate your serving area</li>
          <li>Pricing shown after list is uploaded</li>
        </ul>
      </div>
      <div className="row ml-4">
        <YouTube
          videoId="OXldAPlP8fk"
        />
      </div>
    </>
  )
}

export default Upload;
