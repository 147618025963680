import React, { useState, useEffect } from 'react';
import Upload from './Upload';
import './index.scss';
import axios from 'axios';
import Loading from '../../Common/Loading/index';
import Papa from 'papaparse';
import BillingModal from './Billing';
import Logo from '../../Assets/Images/logo.svg';
import { ToastContainer, toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import Checkout from '../Checkout/index';
import 'react-toastify/dist/ReactToastify.css';
import Success from './Success/index';

const Home = ({ }) => {
  const [file, setFile] = useState(null);
  const [isSearching, setSearching] = useState(false);
  const [loadingText, setLoadingText] = useState(false);
  const [active, setActive] = useState(0);
  const [codes, setCodes] = useState([]);
  const [generated, setGenerated] = useState(0);
  const [isGenerating, setIsGenerating] = useState(false);
  const [billingModal, setBillingModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [totalAmount, setTotalAmount] = useState(null);
  const [transactionHash, setTransactionHash] = useState(null);
  const [couponApplied, setCouponApplied] = useState(false);
  const [total, setTotal] = useState(0);

  const getMappedJson = (data) => {
    let mapped = data.map(c => ({
      logrecno: c['LogRecNo'],
      provider_id: c['Provider Id'],
      frn: c['FRN'],
      providername: c['Provider Name'],
      dbaname: c['DBA name'],
      holdingcompanyname: c['Holding company name'],
      hoconum: c['HocoNum'],
      hocofinal: c['HocoFinal'],
      stateabbr: c['StateAbbr'],
      blockcode: c['BlockCode'],
      techcode: c['TechCode'],
      consumer: c['Consumer'],
      maxaddown: c['MaxAddDown'],
      maxadup: c['MaxAdUp'],
      business: c['Business']
    }))
    return mapped;
  }
  const onUploadCSV = (e) => {
    setActive(1)
    setFile(e.target.files[0])
    Papa.parse(e.target.files[0], {
      header: true,
      complete: function (results) {
        let mappedJson = getMappedJson(results.data)
        setCodes(mappedJson)
        setActive(2)
      }
    });
  }

  const postCSV = async (email) => {
    try {
      let formData = new FormData();
      formData.append('email', email);
      formData.append('file', file)
      let response = await axios.post(`https://csn-fme-api.herokuapp.com/process`,
        formData).catch(e => {
          if (e.response.status === 504) {
            paymentModalToggle();
            setActive(3);
            setSearching(false);
            setLoadingText('')
          } else
            toast.error("Something went wrong");
        })
      if (response) {
        paymentModalToggle();
        setActive(3);
        setSearching(false);
        setLoadingText('')
      }
    } catch (e) {
      console.log(e, e.response.status)
    }
  }

  const onChangeBillingModal = () => {
    if (transactionHash) {
      setBillingModal(!billingModal)
    }
  }

  const onBuy = async (amount) => {
    let formatted = parseInt(String(amount).split('.')[0])
    setTotalAmount(formatted)
    try {
      let transaction = await axios.post(`https://geo-json.herokuapp.com/transactions`, {
        no_of_features: codes.length
      })
      if (transaction.data.success) {
        setTransactionHash(transaction.data.hash)
        paymentModalToggle()
      } else {
      }
    } catch (e) {
      console.log(e.response)
    }
  }
  const onApplyCoupon = async (code) => {
    if (!code) return;
    try {
      let coupon = await axios.get(`https://geo-json.herokuapp.com/coupons/${code}`)
      if (coupon.data.success) {
        setCouponApplied(coupon.data)
        toast.success(`Coupon Applied!`)
      }

    } catch (e) {
      console.log(e.response)
      toast.error(e.response.data.message)
    }
  }
  const paymentModalToggle = () => {
    setPaymentModal(!paymentModal)
    setBillingModal(false)
  }

  const onPayment = async (data) => {
    setSearching(true);
    setLoadingText('processing payment...')
    let source = data.token && data.token.id
    delete data.token
    try {
      await axios.post('https://geo-json.herokuapp.com/stripe/charge', {
        ...data,
        source,
        hash: transactionHash
      })
      postCSV(data.email);
    } catch (e) {
      setSearching(false);
      setLoadingText('')
      toast.error(e.response.data.message)
    }
  }

  const getBillSplitted = () => {
    let totalBlocks = codes.length;
    if (!totalBlocks) {
      return <></>
    } else {
      let thousand = 0, rest = 0
      if (totalBlocks < 1000) {
        thousand = 1000
        rest = 0
      } else {
        thousand = 1000
        rest = totalBlocks - 1000
      }
      let array = [{ features: thousand, rate: 0.03, amount: 0.03 * thousand }]
      if (rest) {
        array.push({ features: rest, rate: 0.02, amount: 0.02 * rest })
      }
      return array.map((a, i) => {
        return (
          <>
            <li className="d-flex">
              <div>{i * 1000 + 1}-{a.features + (i === 0 ? 0 : 1000)}</div>
              <div className="rate">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(a.rate)} per row</div>
            </li>
            <div className="line mt-1" />
          </>
        )
      })
    }
  }

  useEffect(() => {
    let totalBlocks = codes.length;
    if (totalBlocks) {
      let thousand = 0, rest = 0
      if (totalBlocks < 1000) {
        thousand = totalBlocks
        rest = 0
      } else {
        thousand = 1000
        rest = totalBlocks - 1000
      }
      let array = [0.03 * thousand]
      if (rest) {
        array.push(0.02 * rest)
      }
      setTotal(array.reduce((a, c) => a + c, 0))
    }
  }, [codes.length])

  return (
    <>
      <div className={`container ${active === 3 && 'large'}`}>
        <div className="row mt-5">
          <div className="col text-center">
            <div className="logo mb-4">
              <img src={Logo} />
            </div>
            {active === 0 &&
              <>
                <h2>477 2010/2020 Census Block Converter</h2>
                <h4 className="mt-4">Easily convert 2010 to 2020 census blocks</h4>
              </>
            }
          </div>
        </div>

        {active === 0 &&
          <div className="row">
            <div className="col text-center">
              <Upload onUploadCSV={onUploadCSV} />
            </div>
          </div>
        }

        {active === 1 &&
          <div className="processing">
            <h4>Processing CSV...</h4>
          </div>
        }

        {active === 2 &&
          <div className="results">
            <h5>Results</h5>
            <span className="mb-4">Your CSV contains {new Intl.NumberFormat('en-US').format(codes.length)} rows. The price breakdown is below</span>
            <div className="price-breakdown">
              {getBillSplitted()}
              <div className="mt-3">
                <span className="font-weight-bold">Flat Rate:</span>
                <span className="ml-3 h5 font-weight-light">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(300)}</span>
              </div>
              <div className="mt-1">
                <span className="font-weight-bold">Total:</span>
                <span className="ml-3 h5 font-weight-light">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total + 300)}</span>
              </div>
              <button onClick={() => onBuy(total)}>Pay now</button>
            </div>

          </div>
        }

        {active === 3 &&
          <div className="success">
            <Success />
            <p className="mb-0">The results will be emailed to you within the next 30 minutes. If you do not receive
              your results, please reach out to Paul.Solsrud@cooperative-networks.com or call 320-250-1296</p>
          </div>
        }

        <BillingModal
          billingModal={billingModal}
          setBillingModal={() => onChangeBillingModal()}
          totalBlocks={codes.length}
          onBuy={onBuy}
        />

        {isSearching && <Loading
          message={loadingText}
          progress={generated}
          isGenerating={isGenerating}
        />}
      </div>

      <Checkout
        paymentModal={paymentModal}
        toggle={paymentModalToggle}
        onPayment={onPayment}
        totalAmount={totalAmount}
        onApplyCoupon={onApplyCoupon}
        couponApplied={couponApplied}
        setCouponApplied={setCouponApplied}
      />

      <ToastContainer />
    </>
  )
}

export default withRouter(Home);
